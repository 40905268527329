import React from "react";

import { IconContext } from "@react-icons/all-files";
import Button from "@material-ui/core/Button";
import { HiOutlineDownload } from "@react-icons/all-files/hi/HiOutlineDownload";

export default function Resume() {
  function downloadResume() {
    const fileName = "AmySitwalaResume.pdf";
    const link = document.getElementById("downloadResume");
    link.setAttribute("href", `${process.env.GATSBY_ASSETS_URL}/${fileName}`);
    link.setAttribute("target", "_blank");
    link.setAttribute("download", fileName);
    link.click();
  }

  return (
    <div>
      <h1 className={ `header` }>Resume</h1>
      {/* DOWNLOAD RESUME */}
      <Button variant="contained" size="large" color="primary" disableElevation onClick={() => downloadResume()}>
        Download Resume
        <IconContext.Provider value={{ color: "white", size: "20px", style: { margin: "0px 0px 0px 4px"} }}>
          <HiOutlineDownload />
        </IconContext.Provider>
      </Button>
      <a href="/" id="downloadResume"> </a>
    </div>
  );
};
